.noOverflow {
    overflow: hidden !important;
}

.sidePanelOuter{
    background-color: #fff;
    bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 400px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: width linear 0.25s;
    -moz-transition: width linear 0.25s;
    -ms-transition: width linear 0.25s;
    -o-transition: width linear 0.25s;
    transition: width linear 0.25s;
    width: 35%;
}
.modal .sidePanelOuter{
    border-radius: 0 5px 5px 0;
}

.sidePanel {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: white;
    box-sizing: border-box;
    height:100%;
    overflow-y:scroll;
    padding: 20px 20px 0; /*IE Edge does not repect the bottom padding for some reason so we moved it to the bottom of the conFooter*/
}

.sidePanel .conBody {
    padding-bottom: 0; /*for legacy - can be removed after Dec 15*/
}

.sidePanel .conFooter {
    padding-bottom: 20px;
}

.siblingsScrollable {
    overflow-y : scroll;
    max-height: 325px;
}

.mapHeader ~ .sidePanelOuter {
    top:60px;
}

.sidePanel.ng-hide {
    padding: 0;
    right: -40px;
    width: 0;
}

.sidePanel header {
    position: relative;
    margin: 0 0 20px;
}

.sidePanel header h2 span {
    hyphens: auto;
}

.sidePanel .iconCollapseRight {
    margin-left: 5px;
}

.sidePanel .table{
    table-layout: fixed;
}
.sidePanel .table th:first-child,
.sidePanel .table td:first-child {
    width:45%;
}

.sidePanel .table th:nth-child(2),
.sidePanel .table td:nth-child(2) {
    width:30%;
}

.sidePanel .table th:nth-child(3),
.sidePanel .table td:nth-child(3) {
    width:25%;
}
.sidePanel .table th,
.sidePanel .table td {
    word-wrap: break-word;
}

.sidePanel tr button {
    max-width: 100%;
}

.sidePanel tr button span {
    overflow: hidden;
    hyphens: auto;
}

.sidePanel.con:not(:first-child) {
    margin-top: 0;
}

.sidePanel .conHeader .placePagelink {
    float: none;
    font-size: inherit;
}

@media all and (max-width:1005px) {
    .sidePanelOuter{
        bottom: 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, .6);
        height:45%;
        left: 0;
        min-width: 0;
        width:100%;
        right: 0;
        top: auto;
    }

    .modal .sidePanelOuter{
        border-radius: 0 0 5px 5px;
    }
}
@media all and (max-width:767px) {

    .sidePanelOuter {
        box-shadow: none;
        height:100%;
    }

    .modal .sidePanelOuter{
        border-radius: 5px;
    }
    .mapHeader ~ .sidePanelOuter {
        top:60px;
    }
    /*ANC Table does a good job here so reset changes*/
    .sidePanel .table{
        table-layout: auto;
    }
    .sidePanel .table th:first-child,
    .sidePanel .table td:first-child,
    .sidePanel .table th:nth-child(2),
    .sidePanel .table td:nth-child(2),
    .sidePanel .table th:nth-child(3),
    .sidePanel .table td:nth-child(3) {
        width:auto;
    }

}
@media all and (max-width:479px) {
    .sidePanel {
        padding: 4px;
    }
    .sidePanel .conBody {
        border:0;
        padding: 2px
    }
}

.plainTxt, .plainTxt:active, .plainTxt:focus, .plainTxt:hover{
    text-decoration: none !important;
}

.linkBtn {
    background: none;
    border: 0;
}

.leaflet-right .leaflet-control { margin-right: 20px; }
.mapHeader ~ .leaflet-container .leaflet-top .leaflet-control { margin-top: 80px; }
.mapHeader {
    background-color: rgba(112, 107, 99, 0.9);
    line-height: 40px;
    min-height: 60px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    z-index: 1000;
}

    .mapHeader h1 {
        margin-top: 3px;
    }
.browseUi-placeLinkDirectiveContainer {
    padding: 20px
}

@media (max-width: 767px) {
    .browseUi-placeLinkDirectiveContainer {
        padding: 10px
    }
}

.browseUi-placeLinkDirectiveListItem {
    margin-top: 0 !important;
    padding: 5px;
    overflow: hidden;
}

.browseUi-placeLinkDirectiveFourColumnsUl {
    column-count: 4;
    column-gap: 20px;
}

.browseUi-placeLinkDirectiveFiveColumnsUl {
    column-count: 5;
    column-gap: 20px;
}

@media (max-width: 767px) {
    .browseUi-placeLinkDirectiveFiveColumnsUl {
        column-count: 3;
        column-gap: 10px;
    }
}

@media (max-width: 767px) {
    .browseUi-placeLinkDirectiveFourColumnsUl {
        column-count: 3;
        column-gap: 10px;
    }
}
.center {
    text-align: center;
}

.truncate { /*outer elem should have overflow: hidden*/
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map {
    bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

#mapOuter {
    height: 88vh;
}

.embeddedMapContainer {
    border: 1px solid #dbd9d3;
    border-radius: 5px;
    min-height:350px;
    overflow: hidden;
    perspective: 1px;
    -webkit-perspective: 1px; /* perspective here is a css hack to force internal images to adhere to overflow:hidden */
    position: relative;
}

.embeddedMap {
    bottom: 0;
    height: 350px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.modalmap {
    border-radius: 5px;
    overflow: hidden;
    perspective: 1px;
    -webkit-perspective: 1px;
}

.embeddedMap + * {
    margin-top: 360px;
}

/*This property is used to override the Bonsai style of max-width:100% of #modal img */
#modal img.leaflet-marker-icon {
    max-width: none;
}


@media all and (max-width:767px) {
    .modalmap {
        display: none;
    }
}